import React from 'react'

import Layout from '../../components/Layout'
import SignUpForm from '../../components/SignUp'

const SignUpPage = () => (
  <div className="columns">
    <div className="column is-10 is-offset-1">
      <div className="section">
        <h1 className="has-text-white">Join Our Community</h1>
        <div className="card">
          <div className="card-content">
            <section className="section">
              <h4>Sign Up</h4>
              <SignUpForm />
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
)

const SignUp = () => {
  return (
    <Layout>
      <section className="section section-gradient">
        <div className="container">
          <div className="content">
            <SignUpPage />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default SignUp
